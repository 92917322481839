import styled from '@emotion/styled';

import {
  getNotebookAuthor,
  NotebookAuthor,
} from '@app/api/resources/notebook/NotebookAuthor';
import { NotebookPost } from '@app/api/resources/notebook/NotebookPost';

import { doRedirect, getFullRouteUrl } from '@app/services/routeHelpers';

import { Context } from '@app/types/common';

import useI18nUrlData, {
  getI18nUrlDataFromState,
} from '@app/hooks/helpers/useI18nUrlData';

import HeaderContainer from '@app/components/layout/HeaderContainer';
import PageSection from '@app/components/layout/PageSection';
import Loading from '@app/components/Loading';
import NotebookPagingPostsForAuthorGridContainer from '@app/components/notebook/containers/NotebookPagingPostsForAuthorGridContainer';
import NotebookPromoContainer from '@app/components/notebook/containers/NotebookPromoContainer';
import NotebookAuthorHeader from '@app/components/notebook/NotebookAuthorHeader';
import NotebookFooter from '@app/components/notebook/NotebookFooter';
import NotebookIndexItem from '@app/components/notebook/NotebookIndexItem';
import NotebookLogoTitle from '@app/components/notebook/NotebookLogoTitle';
import Pagination from '@app/components/Pagination';
import SeoMetaTags from '@app/components/SeoMetaTags';
import StandardHeadTags from '@app/components/StandardHeadTags';

const NotebookAuthorPage = ({
  notebookAuthor = null,
  initialPageNumber,
}: {
  notebookAuthor?: NotebookAuthor;
  initialPageNumber: number;
}) => {
  const i18nUrlData = useI18nUrlData();
  if (!notebookAuthor) {
    return null;
  }

  const getPostElements = (notebookPosts: NotebookPost[]) =>
    notebookPosts.map(notebookPost => (
      <AuthorGridItem key={notebookPost.id}>
        <NotebookIndexItem post={notebookPost} />
      </AuthorGridItem>
    ));

  const title = `${notebookAuthor.name} – Notebook | MUBI`;
  const url = getFullRouteUrl({
    url: `/notebook/posts/author/${notebookAuthor.id}`,
    i18nUrlData,
  });

  return (
    <>
      <StandardHeadTags title={title} />
      <SeoMetaTags canonicalUrl={url} />

      <HeaderContainer />
      <PageSection useLegacyMQ>
        <NotebookLogoTitleContainer>
          <NotebookLogoTitle subTitle="Author" />
        </NotebookLogoTitleContainer>
        <NotebookAuthorHeaderLayoutContainer>
          <NotebookAuthorHeader author={notebookAuthor} />
        </NotebookAuthorHeaderLayoutContainer>
      </PageSection>
      <PageSection bgColor="midBackground" minHeight="80vh" useLegacyMQ>
        <NotebookPagingPostsForAuthorGridContainer
          author={notebookAuthor}
          initialPage={initialPageNumber}
        >
          {({
            loading: postGridLoading,
            error: postGridError,
            data: postGridData,
          }) => {
            if (postGridError) {
              return null;
            }
            if (postGridLoading) {
              return <Loading padding="10vh 0 0 0" />;
            }

            const {
              notebookPosts,
              getNextPage,
              getPrevPage,
              pageInfo,
              goToPage,
            } = postGridData;
            const postElementsForAuthor = getPostElements(notebookPosts);
            return (
              <>
                <AuthorGridContainer>
                  {postElementsForAuthor}
                </AuthorGridContainer>
                <PaginationContainer>
                  <Pagination
                    getNextPage={getNextPage}
                    getPrevPage={getPrevPage}
                    pageInfo={pageInfo}
                    goToPage={goToPage}
                  />
                </PaginationContainer>
              </>
            );
          }}
        </NotebookPagingPostsForAuthorGridContainer>
      </PageSection>

      <NotebookFooter />
      <NotebookPromoContainer filmsRelatedToPost={[]} />
    </>
  );
};

NotebookAuthorPage.getInitialProps = async ({
  query,
  store,
  isServer,
  res,
}: Context) => {
  const currentState = store.getState();
  const httpContext = currentState?.appState?.httpContext;

  const initialPageNumber = parseInt(query.page as string, 10) || 1;

  let notebookAuthor: NotebookAuthor = null;
  try {
    const notebookAuthorResponse = await getNotebookAuthor(
      httpContext,
      parseInt(query.author_id as string, 10),
    );
    notebookAuthor = notebookAuthorResponse.data;
  } catch (error) {
    doRedirect(
      isServer,
      getFullRouteUrl({
        url: '/notebook',
        i18nUrlData: getI18nUrlDataFromState(currentState),
        includeDomain: false,
      }),
      res,
    );
    return {};
  }

  return {
    notebookAuthor,
    initialPageNumber,
  };
};

const NotebookLogoTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NotebookAuthorHeaderLayoutContainer = styled.div`
  width: 100%;
  padding: 0 8px 8px;
  @media (min-width: ${props => props.theme.mq.tablet}) {
    width: 400px;
    margin: 0 auto;
    padding: 0 8px 14px 8px;
  }
  @media (min-width: ${props => props.theme.mq.desktop}) {
    width: 750px;
  }
  @media (min-width: ${props => props.theme.mq.wide}) {
    width: 1020px;
  }
`;

const AuthorGridContainer = styled.div`
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
  @media (min-width: ${props => props.theme.mq.tablet}) {
    margin-left: -8px;
    margin-right: -8px;
  }
`;

const AuthorGridItem = styled.div`
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 12px;
  width: 100%;
  @media (min-width: ${props => props.theme.mq.tablet}) {
    width: 50%;
  }
  @media (min-width: ${props => props.theme.mq.desktop}) {
    width: 33.3%;
  }
  @media (min-width: ${props => props.theme.mq.wide}) {
    width: 25%;
  }
`;

const PaginationContainer = styled.div`
  padding: 15px 0 20px;
  @media (min-width: ${props => props.theme.mq.tablet}) {
    padding: 45px 0;
  }
`;

export default NotebookAuthorPage;
