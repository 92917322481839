import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import { NotebookAuthor } from '@app/api/resources/notebook/NotebookAuthor';

import ImageBase from '@app/components/ImageBase';

const NotebookAuthorHeader = ({ author }: { author: NotebookAuthor }) => {
  const { t } = useTranslation('user_profile');

  return (
    <Container>
      {author.avatar_url && (
        <AuthorAvatarContainer>
          <AuthorAvatar
            src={`${author.avatar_url}?size=60x`}
            alt={
              author?.name
                ? t('user_profile:user_profile.profile_picture', {
                    name: author.name,
                  })
                : t(
                    'user_profile:user_profile.edit_profile_modal.profile_picture',
                  )
            }
          />
        </AuthorAvatarContainer>
      )}
      <AuthorInfo>
        <AuthorName>{author.name}</AuthorName>
        <AuthorBio>{author.bio}</AuthorBio>
      </AuthorInfo>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
`;

const AuthorAvatarContainer = styled.div``;

const AuthorAvatar = styled(ImageBase)`
  width: 85px;
  margin-right: 14px;
`;

const AuthorInfo = styled.div`
  width: 100%;
`;

const AuthorName = styled.div`
  color: ${props => props.theme.color.darkText};
  font-size: 24px;
  line-height: 24px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const AuthorBio = styled.div`
  font-family: ${props => props.theme.font.body};
  font-size: 14px;
`;

export default NotebookAuthorHeader;
