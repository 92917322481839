import HttpService from '@app/api/http-service';
import { Meta } from '@app/api/pagination';
import { NotebookPost } from '@app/api/resources/notebook/NotebookPost';
import { ObjectOfStrings } from '@app/api/utility-types';
import { getPaginationQS } from '@app/api/utils';

export type NotebookAuthor = {
  id: number;
  name: string;
  canonical_url: string;
  bio: string;
  avatar_url: string;
};

export const getNotebookAuthor = (
  httpContext: ObjectOfStrings,
  authorId: number,
): {
  data: NotebookAuthor;
} => HttpService(httpContext).get(`/notebook/authors/${authorId}`);

export const getNotebookAuthorPosts = (
  httpContext: ObjectOfStrings,
  authorId: number,
  page: number,
  numPerPage: number,
): {
  data: {
    posts: NotebookPost[];
    meta: Meta;
  };
} => {
  const paginationQS = getPaginationQS(page, numPerPage);
  return HttpService(httpContext).get(
    `/notebook/authors/${authorId}/posts?${paginationQS}`,
  );
};

export const getNotebookAuthorsRecentlyPosted = (
  httpContext: ObjectOfStrings,
): {
  data: NotebookAuthor[];
} => HttpService(httpContext).get('/notebook/authors/recent');

export const getNotebookAuthorsContributors = (
  httpContext: ObjectOfStrings,
): {
  data: NotebookAuthor[];
} => HttpService(httpContext).get('/notebook/authors/contributors');
