import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';

import { emptyMeta, Meta } from '@app/api/pagination';
import {
  getNotebookAuthorPosts,
  NotebookAuthor,
} from '@app/api/resources/notebook/NotebookAuthor';
import {
  NotebookPost,
  NotebookPostGridData,
  PageOfNotebookPosts,
  updatePageQueryStringAndScroll,
} from '@app/api/resources/notebook/NotebookPost';

import { pick } from '@app/services/utils';

import {
  addNotebookPosts,
  addPageOfNotebookPostsForAuthor,
} from '@app/actions/notebook/NotebookPostActions';

import useAppSelector from '@app/hooks/utils/useAppSelector';

type NotebookPagingPostsForAuthorGridContainerProps = {
  children: (props: {
    loading: boolean;
    error: boolean;
    data: NotebookPostGridData;
  }) => JSX.Element;
  initialPage?: number;
  author: NotebookAuthor;
};

const NotebookPagingPostsForAuthorGridContainer = ({
  children,
  initialPage = 1,
  author,
}: NotebookPagingPostsForAuthorGridContainerProps) => {
  const [notebookPosts, setNotebookPosts] = useState<NotebookPost[]>([]);
  const [pageInfo, setPageInfo] = useState<Meta>(emptyMeta);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  const { httpContext, globalNotebookPosts, authorPostsPages } = useAppSelector(
    state => ({
      httpContext: state.appState.httpContext,
      globalNotebookPosts: state.notebookPost.notebookPosts,
      authorPostsPages: state.notebookPost.authorPostsPages,
    }),
    shallowEqual,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    getPageOfPostsForAuthor(initialPage);
  }, []);

  const getPageOfPostsForAuthor = async (page_num: number) => {
    setLoading(true);
    try {
      const notebookPostsFromCache =
        getPageOfNotebookPostsForAuthorFromCache(page_num);
      if (notebookPostsFromCache) {
        await setPageOfPostsFromCache(notebookPostsFromCache);
      } else {
        await setPageOfPostsFromBackend(page_num);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const setPageOfPostsFromCache = async (
    notebookPostsFromCache: PageOfNotebookPosts,
  ) => {
    const { pageOfNotebookPosts, pageMeta } = notebookPostsFromCache;
    setNotebookPosts(pageOfNotebookPosts);
    setPageInfo(pageMeta);
  };

  const setPageOfPostsFromBackend = async (page_num: number) => {
    const notebookPostsResponse = await getNotebookAuthorPosts(
      httpContext,
      author.id,
      page_num,
      24,
    );
    const newNotebookPosts = notebookPostsResponse?.data?.posts;
    const newNotebookPostsMeta = notebookPostsResponse?.data?.meta;
    const notebookPostIds = newNotebookPosts.map(
      notebookPost => notebookPost.slug,
    );

    dispatch(addNotebookPosts(newNotebookPosts));
    dispatch(
      addPageOfNotebookPostsForAuthor(
        author.id,
        notebookPostIds,
        page_num,
        newNotebookPostsMeta,
      ),
    );
    setNotebookPosts(newNotebookPosts);
    setPageInfo(newNotebookPostsMeta);
  };

  const isPageCached = (auth: NotebookAuthor, page_num: number) => {
    if (authorPostsPages[auth.id]) {
      return authorPostsPages[auth.id].pages[page_num];
    }
    return false;
  };

  const getPageOfNotebookPostsForAuthorFromCache = (
    page_num: number,
  ): PageOfNotebookPosts => {
    if (isPageCached(author, page_num)) {
      const { postIds, pageMeta } = authorPostsPages[author.id].pages[page_num];
      const pageOfNotebookPostsObj = pick(globalNotebookPosts, postIds);
      const pageOfNotebookPosts: NotebookPost[] = Object.values(
        pageOfNotebookPostsObj,
      );
      return {
        pageOfNotebookPosts,
        pageMeta,
      };
    }
    return null;
  };

  const getCurrentPageNumber = () => pageInfo?.current_page ?? 0;

  const getTotalPagesNum = () => pageInfo?.total_pages ?? 0;

  const getNextPage = () => {
    const nextPageNum = getCurrentPageNumber() + 1;
    const totalPagesNum = getTotalPagesNum();
    if (nextPageNum <= totalPagesNum) {
      updatePageQueryStringAndScroll(nextPageNum);
      getPageOfPostsForAuthor(nextPageNum);
    }
  };

  const getPrevPage = () => {
    const prevPageNum = getCurrentPageNumber() - 1;
    if (prevPageNum > 0) {
      updatePageQueryStringAndScroll(prevPageNum);
      getPageOfPostsForAuthor(prevPageNum);
    }
  };

  const goToPage = (pageNum: number) => {
    const totalPagesNum = getTotalPagesNum();
    if (pageNum > 0 && pageNum <= totalPagesNum) {
      updatePageQueryStringAndScroll(pageNum);
      getPageOfPostsForAuthor(pageNum);
    }
  };

  const data = {
    pageInfo,
    notebookPosts,
    getNextPage,
    getPrevPage,
    goToPage,
  };

  return children({
    loading,
    error,
    data,
  });
};

export default NotebookPagingPostsForAuthorGridContainer;
